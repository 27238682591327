input,
select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
}

input:focus,
select:focus {
    border-color: #007bff; /* Màu viền khi focus */
}

select {
    background-size: 0.65rem auto;
}

