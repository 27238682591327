@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
}

.library-homepage {
  max-width: 1400px;
  margin: 0 auto;
}

.hero-section {
  position: relative;
  height: 600px;
  color: white;
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 3.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.btn-explore, .btn-register {
  padding: 12px 30px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-explore {
  background-color: white;
  color: #667eea;
}

.btn-register {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 50px 20px;
}

.service-card {
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  height: 400px;
  display: flex;
  align-items: flex-end;
  transition: transform 0.3s ease;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.service-card:hover {
  transform: scale(1.05);
}

.card-content {
  background: rgba(255,255,255,0.9);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  width: calc(100% - 40px);
}

.service-link {
  display: inline-block;
  margin-top: 10px;
  color: #667eea;
  text-decoration: none;
  font-weight: bold;
}

.book-service {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23667eea" fill-opacity="1" d="M0,160L48,176C96,192,192,224,288,229.3C384,235,480,213,576,192C672,171,768,149,864,160C960,171,1056,213,1152,229.3C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320L192,320L96,320L0,320Z"></path></svg>');
}

.search-service {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%2364b5f6" fill-opacity="1" d="M0,160L48,176C96,192,192,224,288,229.3C384,235,480,213,576,192C672,171,768,149,864,160C960,171,1056,213,1152,229.3C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320L192,320L96,320L0,320Z"></path></svg>');
}

.community-service {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23a569bd" fill-opacity="1" d="M0,160L48,176C96,192,192,224,288,229.3C384,235,480,213,576,192C672,171,768,149,864,160C960,171,1056,213,1152,229.3C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320L192,320L96,320L0,320Z"></path></svg>');
}

.library-stats {
  background-color: #f7f9fc;
  padding: 50px 0;
}

.stats-background {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 40px 0;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.stat-item {
  background-color: rgba(255,255,255,0.1);
  padding: 30px;
  border-radius: 15px;
  color: white;
}

.stat-number {
  display: block;
  font-size: 3em;
  font-weight: bold;
}

.stat-label {
  font-size: 1em;
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .services-container, .stat-grid {
    grid-template-columns: 1fr;
  }

  .hero-section {
    height: 500px;
  }

  .hero-content h1 {
    font-size: 2.5em;
  }
}

