.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 0.5rem;
  }
  
  .pagination-button {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    background-color: white;
    color: #374151;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .pagination-button:hover {
    background-color: #f3f4f6;
  }
  
  .pagination-button.active {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
  }
  
  .pagination-button:disabled {
    background-color: #f3f4f6;
    color: #9ca3af;
    cursor: not-allowed;
  }
  
  .pagination-info {
    color: #6b7280;
    margin: 0 1rem;
  }