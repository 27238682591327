.return-book-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.return-book-modal-content {
    background-color: white;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.return-book-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.return-book-modal-body {
    margin-bottom: 15px;
}

.modal-info-section {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.return-date-section {
    display: flex;
    flex-direction: column;
}

.return-date-section label {
    margin-bottom: 5px;
}

.return-date-section input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.return-book-modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
}

.cancel-btn, .return-btn {
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-btn {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
}

.return-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.return-btn:disabled, .cancel-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}