.book-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .book-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .book-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .book-card {
    display: flex;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .book-card:hover {
    transform: translateY(-2px);
  }
  
  .book-card-header {
    padding: 1rem;
    width: 200px;
    flex-shrink: 0;
  }
  
  .book-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  .book-card-content {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .book-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .book-author {
    color: #4b5563;
    margin-bottom: 0.5rem;
  }
  
  .book-description {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .book-card-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .book-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #2563eb;
  }
  
  .add-to-cart-button {
    background-color: #2563eb;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-to-cart-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 90%;
    max-width: 800px;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #4b5563;
  }
  
  .modal-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .modal-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .modal-author {
    color: #4b5563;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .modal-description {
    color: #1f2937;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

