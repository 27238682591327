.wrapper {
    width: 100%;
    height: 300px;
    border: 0;
    background-color: rgba(0, 0, 90, 1);
    border-radius: 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrapper img {
    padding-left: 5%;
    height: 100px;
    width: auto;
}

.contact {
    margin-top: 15px;
    margin-left: 10%;
    font-size: 24px;
    line-height: 2;
    display: block;
    color: #fff;
}

.box {
    background-color: black;
    background-color: black;
    color: white;
    margin-top: auto;
    width: 100%;
    height: 32px;
    text-align: center;
    padding: 5px;
}
.footer-text-box{
    font-style: italic;
}