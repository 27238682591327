.statistics-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    gap: 20px
}

.statistics-title {
    text-align: center;
}

.statistics-section {
    flex: 1; /* Biểu đồ chia đều chiều rộng */
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}


