.user-profile-container {
    max-width: 32rem;
    margin: 50px auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.user-profile-container:hover {
    transform: translateY(-5px);
}

.user-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    border-bottom: 2px solid #f3f4f6;
    padding-bottom: 1rem;
}

.user-profile-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: #1f2937;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.edit-button, .save-button, .cancel-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.edit-button {
    background-color: #3b82f6;
    color: white;
    box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.edit-button:hover {
    background-color: #2563eb;
    box-shadow: 0 6px 8px rgba(59, 130, 246, 0.3);
}

.action-buttons {
    display: flex;
    gap: 1rem;
}

.save-button {
    background-color: #10b981;
    color: white;
    box-shadow: 0 4px 6px rgba(16, 185, 129, 0.2);
}

.save-button:hover {
    background-color: #059669;
    box-shadow: 0 6px 8px rgba(16, 185, 129, 0.3);
}

.cancel-button {
    background-color: #ef4444;
    color: white;
    box-shadow: 0 4px 6px rgba(239, 68, 68, 0.2);
}

.cancel-button:hover {
    background-color: #dc2626;
    box-shadow: 0 6px 8px rgba(239, 68, 68, 0.3);
}

.profile-label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #4b5563;
    transition: color 0.3s ease;
}

.profile-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 2px solid #e5e7eb;
    border-radius: 0.625rem;
    outline: none;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.profile-input:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.15);
}

.profile-text {
    color: #111827;
    font-size: 1rem;
}

.profile-section {
    margin-bottom: 1.5rem;
    position: relative;
}

/* Hover and focus states for sections */
.profile-section:hover .profile-label {
    color: #3b82f6;
}