.borrow-history-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.borrow-history-modal-content {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 70%;
    overflow-y: auto;
}

.borrow-history-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.borrow-history-modal-body {
    padding: 15px;
}

.close-modal-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}