.contact-section {
    padding: 40px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
  
.section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}
  
.contact-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
  
.detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
.detail h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}
  
.detail p,
.detail a {
    font-size: 14px;
    color: #666;
    text-decoration: none;
}
  
.map-container {
    margin-top: 20px;
    position: relative;
    margin-bottom: 100px;
}
.map-embed {
    width: 100%;
    height: 500px;
    border: 0;
    position: relative;
}
.meber-develop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Căn giữa */
    gap: 500px; /* Khoảng cách giữa các phần tử */
    margin-top: 80px;
    margin-bottom: 50px;
}

.members {
    display: flex;
    flex-direction: column;
    gap: 50px; /* Khoảng cách giữa các chi tiết thành viên */
}

.member-detail {
    display: flex;
    align-items: center;
    gap: 10px; /* Khoảng cách giữa ảnh và tên */
}

.member-detail img {
    width: 100px; /* Kích thước ảnh */
    height: 100px;
    border-radius: 50%; /* Ảnh tròn */
    object-fit: cover;
}

  