.table-container {
  padding: 30px;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px 16px;
  text-align: left;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.image-column {
  width: 150px;
  text-align: center;
}

.image-column img {
  max-width: 100%;
  height: auto;
}

.button-option {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.button-option button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.button-option button:hover {
  background-color: #0056b3;
}

th:last-child {
  width: 250px;
}

td.description-column {
  width: 350px;
  word-wrap: break-word;
  max-width: 400px;
}

td.quantity {
  width: 120px;
  text-align: center;
}