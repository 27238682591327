.manage-section {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    background-color: #88b0c2; /* Background color as in the image */
    padding: 7px;
    padding-left: 25px;
}
  
.manage-option {
    text-align: center;
    color: #000;
    text-decoration: none;
    transition: transform 0.3s;
    justify-items: center;
    background-color: #88b0c2;
    border: none;
    font-size: larger;
}
  
.manage-option:hover {
  transform: scale(1.1); 
}
.manage-option.active {
    background-color: #005f73; 
    transform: scale(1.15); 
    color: #000; 
    transition: transform 0.3s, background-color 0.3s;
}
.manage-option-icon {
    width: 50px;
    height: 50px;
    border: 2px solid black;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
  
.label {
    display: block;
    font-family: "Protest Strike";
}

.searchbar-option {
    justify-content: center;
    align-items: center; 
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 20px; 
  }