/* Header.css */
.header {
    background-color: #00358c;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 0px;
    padding-right: 10px;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-logo {
    width: 150px;
    height: auto;
    margin-right: 20px;
}

.header-left a {
    font-size: 1.8em;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    /* Bóng mờ giống hình ảnh */
    text-decoration: none;
}

.header-nav {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-top: 70px;
    padding-right: 10px;
    width: 100%;
}

.nav-link {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, text-decoration 0.3s;
}

.nav-link:hover {
    background-color: #88b0c2;
    
}
.nav-link.active {
    background-color: #88b0c2; /* Màu nền khi được chọn */
    
}

.nav-link:last-child {
    background-color: #ffffff;
    /* Nền trắng cho nút Đăng nhập/Đăng ký */
    color: #8B0000;
    /* Màu chữ đỏ sậm */
    font-weight: bold;
    border-radius: 5px;
    padding: 8px px;
    margin-bottom: 3px;
}

.nav-link:last-child:hover {
    background-color: #e6e6e6;
}


@media (max-width: 1000px) {
    .header {
        flex-direction: column; /* Chuyển header sang dạng cột */
        align-items: flex-start;
    }

    .header-left {
        margin-bottom: 10px; /* Tạo khoảng trống giữa logo và nav */
    }

    .header-nav {
        flex-direction: column; /* Chuyển menu sang dạng cột */
        align-items: flex-start;
        gap: 8px; /* Thu hẹp khoảng cách giữa các liên kết */
        width: 100%; /* Cho phép menu chiếm toàn bộ chiều rộng */
    }

    .nav-link {
        font-size: 1em; /* Giảm kích thước font trên màn hình nhỏ */
        padding: 10px;
    }
}

/* Styles cho dropdown tài khoản */
.account-dropdown-container {
    position: relative;
}

.account-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    translate: 0 -10%;
}
.account-trigger span{
    translate: 0 -20%;
}
.account-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    border-radius: 5px;
    min-width: 200px;
    z-index: 1000;
}

.account-dropdown-container:hover .account-dropdown {
    display: block;
}

.dropdown-item {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.logout-item {
    cursor: pointer;
    color: #8B0000;
    font-weight: bold;
}

.logout-item:hover {
    background-color: #f0f0f0;
}