* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home_page {
    font-family: "Poppins", sans-serif;
    background: none;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
}

.body-content{
    margin-bottom: 50px;
    min-height: 700px;
}
